<template>
  <component :is="userData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <div class="card mb-5 mb-xl-10"  v-if="userData"
      pills>
      <div class="card-body">
        <profil-edit-tab-account
          :user-data="userData"
          class="mt-2 pt-75"
        />
      </div>
    </div>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import userStoreModule from '../userStoreModule'
import axios from "axios"
import ProfilEditTabAccount from './ProfilEditTabAccount.vue'
// import ProfilEditTabInformation from './ProfilEditTabInformation.vue'
// import ProfilEditTabSocial from './ProfilEditTabSocial.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    ProfilEditTabAccount,
    // ProfilEditTabInformation,
    // ProfilEditTabSocial,
  },
  setup() {
    const userData = ref([])

    const USER_APP_STORE_MODULE_NAME = 'app-user'
    const pengguna = JSON.parse(localStorage.getItem('userData'));
    const url = localStorage.getItem('baseapi');

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    onMounted(() => {
      loaddata()
    })

    const loaddata = ()=>{
      axios.get(url+'user/'+ router.currentRoute.params.id, {
      headers: {
          "Content-type": "application/json",
          "xth": pengguna.token,
          }
          })
      .then(response => { userData.value = response.data; console.log(response.data) })
      .catch(error => {
        if (error.response.status === 404) {
          userData.value = undefined
        }
      })
    }

    return {
      userData,
      loaddata
    }
  },
}
</script>

<style>

</style>
