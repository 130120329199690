<template>
  <div>

    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="userData.avatar"
          :text="avatarText(userData.nama)"
          :variant="`light-${resolveUserRoleVariant(userData.hak_akses)}`"
          size="90px"
          rounded
        />
      </template>
      <h4 class="mb-1">
        {{ userData.nama }}
      </h4>
    </b-media>

    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>

        <!-- Field: Username -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Username"
            label-for="username"
          >
            <b-form-input
              id="username"
              v-model="userData.username"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Full Name -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Nama"
            label-for="full-name"
          >
            <b-form-input
              id="full-name"
              v-model="userData.nama"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Hak Akses"
            label-for="email"
          >
          <select disabled class="form-control" id="exampleFormControlSelect1" v-model="userData.hak_akses" >
              <option v-for="dt in akses" :key="dt">{{dt.nama}}</option>
          </select>
            <!-- <b-form-input
              id="email"
              v-model="userData.hak_akses"
              type="text"
            /> -->
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="ubah"
    >
      Save Changes
    </b-button>
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,BToast
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, onMounted, inject } from '@vue/composition-api'
import useUsersList from '../profil-list/useUsersList'
import axios from 'axios';
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { inject } from 'vue'
import Swal from 'sweetalert2'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    ToastificationContent,
    BToast
    
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { resolveUserRoleVariant } = useUsersList()

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const permissionsData = [
      {
        module: 'Admin',
        read: true,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'Staff',
        read: false,
        write: true,
        create: false,
        delete: false,
      },
      {
        module: 'Author',
        read: true,
        write: false,
        create: true,
        delete: false,
      },
      {
        module: 'Contributor',
        read: false,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'User',
        read: false,
        write: false,
        create: false,
        delete: true,
      },
    ]

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.userData.avatar = base64
    })

    const  url = localStorage.getItem('baseapi');
    const  pengguna = JSON.parse(localStorage.getItem('userData'));

    onMounted(() => {
      getakses()
    })

    const password = ref('')
    const swal = inject('$swal')

    const ubah=()=>{
      axios.put(url+"user/"+router.currentRoute.params.id,
      {
        nama: props.userData.nama,
        username: props.userData.username,
        // password: password.value,
        hak_akses: props.userData.hak_akses,
      },{
        headers:{
          xth: pengguna.token
        }
      }).then(response =>{
        console.log(response)
        Swal.fire({
          position: 'top-end',
          title: 'Success',
          text: 'Data berhasil diubah',
          icon: 'success',
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
       
      }).catch(error =>{
        Swal.fire({
          position: 'top-end',
          title: 'Failed',
          text: error.response.data,
          icon: 'error',
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        // console.log(error)
      })
    }

    const resetpassword=()=>{
      var id = router.currentRoute.params.id;
      axios.post(url+"user_reset",
      {
        id: id,
      },{
        headers:{
          xth: pengguna.token
        }
      }).then(response =>{
        console.log(response)
       
      }).catch(error =>{
        console.log(error)
      })
    }

    const akses = ref([])
    const getakses = () => {
      axios.get(url+'hak_akses', {
        headers: {
            "Content-type": "application/json",
            "xth": pengguna.token,
            }
        })
        .then(response => {
          // const { users, total } = response.data.data
          var userss = response.data.data
          akses.value = userss
          console.log(akses.value)
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Welcome`,
              icon: 'CoffeeIcon',
              variant: 'success',
              text: `You have successfully logged in as . Now you can start to explore!`,
            },
          })
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching users list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    return {
      resolveUserRoleVariant,
      avatarText,
      roleOptions,
      statusOptions,
      permissionsData,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
      getakses,
      akses,
      password,
      ubah,
      resetpassword
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
